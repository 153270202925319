import { default as _91screenId_939myQJNFF2FMeta } from "/opt/build/repo/pages/dynamic/[screenId].vue?macro=true";
import { default as _91screenId_93rhzLZLJfmaMeta } from "/opt/build/repo/pages/dynamic4k/[screenId].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
export default [
  {
    name: "dynamic-screenId",
    path: "/dynamic/:screenId()",
    component: () => import("/opt/build/repo/pages/dynamic/[screenId].vue")
  },
  {
    name: "dynamic4k-screenId",
    path: "/dynamic4k/:screenId()",
    component: () => import("/opt/build/repo/pages/dynamic4k/[screenId].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  }
]